@import './reset.scss';
@import './buttons.scss';
@import './colors.scss';
@import './fonts.scss';
@import './grid.scss';
@import './placeholders.scss';
@import '../utils/medias.scss';

html,
body,
#root {
	width: 100%;
}

body {
	font-family: $primary-font;
	font-weight: 400;
	font-size: 16px;

	&.no-scroll {
		overflow: hidden;
	}
}

#root {
	// min-width: 400px;
}

.container-fluid {
	max-width: 1350px;
	margin: 0 auto;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	flex-wrap: wrap;
	width: 100%;
	border-radius: 8px;

	@include md {
		width: 95%;
	}

	@include laptop {
		width: 90%;
	}

	&.align-center {
		align-items: center;
		height: 100%;
	}
}

.container {
	border-radius: 8px;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	flex-wrap: wrap;
	margin: 0 auto;
	padding: 0 30px;
	

	@include md {
		padding: 0 50px;
	}

	@include lg {
		padding: 0 25px;
	}

	@include laptop {
		padding: 0 75px;
	}

	&.align-center {
		align-items: center;
		height: 100%;
	}

	&.gutter {
		display: block;

		.row {
			width: auto;
			margin: 0 -15px;
			
			.col {
				padding: 0 15px;
			}
		}
	}
}

.d-none {
	display: none;
}

.d-block {
	display: block;
}

.row {
	width: 100%;

	&.flex {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		flex-wrap: wrap;
	}
}

.overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: $box-shadow;
}

.loading-component {
	position: relative;
	width: 100%;
	height: 100px;

	&::after {
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		height: 70px;
		width: 70px;
		border-top: 8px solid $blue;
		border-right: 8px solid $blue;
		border-bottom: 8px solid $blue;
		border-left: 8px solid transparent;
		animation: loading 2s infinite linear;
		border-radius: 50%;
	}
}
.loading-page {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: $white;
	z-index: 999;
	display: none;

	&.loading {
		display: block;
	}

	&::after {
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		height: 70px;
		width: 70px;
		border-top: 8px solid $blue;
		border-right: 8px solid $blue;
		border-bottom: 8px solid $blue;
		border-left: 8px solid transparent;
		animation: loading 2s infinite linear;
		border-radius: 50%;
	}

	// @keyframes loading {
	// 	from { transform: translate(-50%, -50%) rotate(0) }
	// 	to { transform: translate(-50%, -50%) rotate(360deg) }
	// }
}

.red-text {
	color: $red;
}

.content-select select{
	//appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
}
.content-select select::-ms-expand {
    display: block;
}
.content-select{
	max-width: 250px;
	position: relative;
}
 
.content-select select{
	display: inline-block;
	width: 100%;
	cursor: pointer;
  	padding: 7px 10px;
  	height: 42px;
  	outline: 0; 
  	border: 0;
	border-radius: 0;
	background: white;
	color: #7b7b7b;
	font-size: 1em;
	color: #999;
	font-family: 
	'Quicksand', sans-serif;
	border:2px solid rgba(0,0,0,0.2);
    border-radius: 12px;
    position: relative;
    transition: all 0.25s ease;
}
 
.content-select select:hover{
	background: $blue;
}
 
/* 
Creamos la fecha que aparece a la izquierda del select.
Realmente este elemento es un cuadrado que sólo tienen
dos bordes con color y que giramos con transform: rotate(-45deg);
*/
.content-select i{
	position: absolute;
	right: 20px;
	top: calc(50% - 13px);
	width: 16px;
	height: 16px;
	display: block;
	border-left:4px solid #2AC176;
	border-bottom:4px solid #2AC176;
	transform: rotate(-45deg); /* Giramos el cuadrado */
	transition: all 0.25s ease;
}
 
.content-select:hover i{
	margin-top: 3px;
}

//Search Bar Style
.search {
	width: 100%;
	position: relative;
	display: flex;
  }
  
  .searchTerm {
	width: 100%;
	border: 3px solid #00B4CC;
	border-right: none;
	padding: 5px;
	height: 20px;
	border-radius: 5px 0 0 5px;
	outline: none;
	color: #9DBFAF;
  }
  
  .searchTerm:focus{
	color: #00B4CC;
  }
  
  .searchButton {
	width: 40px;
	height: 36px;
	border: 1px solid #00B4CC;
	background: #00B4CC;
	text-align: center;
	color: #fff;
	border-radius: 0 5px 5px 0;
	cursor: pointer;
	font-size: 20px;
  }
  
  /*Resize the wrap to see the search bar change!*/
  .wrap{
	width: 30%;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
  }

  //Badges
  .badge {
	background-color: $active;
	color: white;
	font-size: 12px;
	padding: 4px 8px;
	text-align: center;
	border-radius: 5px;
	font-style: italic;
  }

  .badge-collabs {
	background-color: #00B4CC;
	color: white;
	font-size: 12px;
	padding: 4px 8px;
	text-align: center;
	border-radius: 5px;
	font-style: normal;
  }

  .badge-details {
	background-color: $blue-light;
	color: white;
	font-size: 12px;
	padding: 4px 8px;
	text-align: center;
	border-radius: 5px;
	cursor: pointer;
  }

  .loader-details {
    width: 26px;
    height: 26px;
    border: 3px solid #FFF;
    border-bottom-color: $blue-light;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;	
    }

    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
    } 

	.loader-contents {
		width: 320px;
		height: 150px;
		margin: auto;
		display: block;
		position: relative;
		background: #FFF;
		box-sizing: border-box;
	  }
	  .loader-contents::after {
		content: '';  
		width: calc(100% - 30px);
		height: calc(100% - 30px);
		top: 15px;
		left: 15px;
		position: absolute;
		background-image: linear-gradient(100deg, transparent, rgba(255, 255, 255, 0.5) 50%, transparent 80%), linear-gradient(#DDD 56px, transparent 0), linear-gradient(#DDD 24px, transparent 0), linear-gradient(#DDD 18px, transparent 0), linear-gradient(#DDD 66px, transparent 0);
		background-repeat: no-repeat;
		background-size: 75px 130px, 55px 56px, 160px 30px, 260px 20px, 290px 56px;
		background-position: 0% 0, 0 0, 70px 5px, 70px 38px, 0px 66px;
		box-sizing: border-box;
		animation: animloader 1s linear infinite;
	  }
	  
	  @keyframes animloader {
		0% {
		  background-position: 0% 0, 0 0, 70px 5px, 70px 38px, 0px 66px;
		}
		100% {
		  background-position: 150% 0, 0 0, 70px 5px, 70px 38px, 0px 66px;
		}
	  }

	  //Loader UpdateFiles

	  .loader_files {
		width: 175px;
		height: 80px;
		display: block;
		margin:auto;
		background-image: linear-gradient(#263238 50px, transparent 0), radial-gradient(circle 25px at 25px 25px, #FFF 100%, transparent 0), radial-gradient(circle 50px at 50px 50px, #FFF 100%, transparent 0), radial-gradient(circle 25px at 25px 25px, #FFF 100%, transparent 0), linear-gradient(#FFF 50px, transparent 0);
		background-size: 64px 6px, 50px 50px, 100px 76px, 50px 50px, 120px 40px;
		background-position: 55px 60px, 0px 30px, 37px 0px, 122px 30px, 25px 40px;
		background-repeat: no-repeat;
		position: relative;
		box-sizing: border-box;
	  }
	  .loader_files::after {
		content: '';  
		position: absolute;
		left: 50%;
		transform: translateX(-50%) rotate(-180deg);
		top: 62px;
		height: 64px;
		width: 60px;
		background-color: #FFF;
		background-image: linear-gradient(#DDD 20px, transparent 0), linear-gradient(#DDD 5px, transparent 0), linear-gradient(#DDD 10px, transparent 0), linear-gradient(#DDD 10px, transparent 0);
		background-size: 50px 20px;
		background-position: 5px 36px, 5px 25px, 5px 10px;
		background-repeat: no-repeat;
		border-radius: 2px 2px 4px 4px;
		z-index: 10;
		box-shadow: 0px -4px 7px rgba(0, 0, 0, 0.5);
		box-sizing: border-box;
		animation: animloader 4s linear infinite;
	  }
	  
	  @keyframes animloader {
		0% {
		  height: 64px;
		}
		90%, 100% {
		  height: 0px;
		}
	  }  
  //Search Bar
//input csv
.upload-btn-wrapper {
	position: relative;
	overflow: hidden;
	display: inline-block;
  }
  
  .btn {
	border: 2px solid #1141B0;
	color: #1141B0;
	background-color: white;
	padding: 8px 20px;
	border-radius: 8px;
	font-size: 12px;
	font-weight: bold;
  }
  
  .upload-btn-wrapper input[type=file] {
	font-size: 100px;
	position: absolute;
	left: 0;
	top: 0;
	opacity: 0;
  }

  //Table Import CSS
  /////////////////////////

  .container-table {
	max-width: 1000px;
	margin-left: auto;
	margin-right: auto;
	padding-left: 10px;
	padding-right: 10px;
  }
  
  
  .responsive-table {
	tr {
	  border-radius: 5px;
	  padding: 25px 30px;
	  display: flex;
	  justify-content: space-between;
	  margin-bottom: 25px;
	}
	.table-header {
	  background-color: #1141B0;
	  font-size: 14px;
	  letter-spacing: 0.03em;
	  color: white;
	  
	}
	.table-row {
	  background-color: #ffffff;
	  box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.1);
	}
	.col-1 {
	  flex-basis: 10%;
	}
	.col-2 {
	  flex-basis: 40%;
	}
	.col-3 {
	  flex-basis: 25%;
	}
	.col-4 {
	  flex-basis: 25%;
	}
	
	@media all and (max-width: 767px) {
	  .table-header {
		display: none;
	  }
	  .table-row{
		
	  }
	  tr {
		display: block;
	  }
	  .col {
		
		flex-basis: 100%;
		
	  }
	  .col {
		display: flex;
		padding: 10px 0;
		&:before {
		  color: #6C7A89;
		  padding-right: 10px;
		  content: attr(data-label);
		  flex-basis: 50%;
		  text-align: right;
		}
	  }
	}
  }

  /////Spinner Import Publishers
  .loader-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 0vh;
  }
  
  .loader-container {
	height: 5px;
	width: 80%;
	max-width: 500px;
	--c: no-repeat linear-gradient(#1141de 0 0);
	background: var(--c), var(--c), #f3f5f9;
	background-size: 60% 100%;
	animation: l16 3s infinite;
	align-items: center;
	display: flex;
	flex-direction: column;
  }
  
  @keyframes l16 {
	0%   {background-position: -150% 0, -150% 0}
	66%  {background-position: 250% 0, -150% 0}
	100% {background-position: 250% 0, 250% 0}
  }
  
// Banner de import publishers
.notice {
	background-color: #f5f4c8; 
	color: #72691c; 
	border: 1px solid #f8f3d7; 
	border-radius: 5px; 
	padding: 15px; 
	margin: 20px 0; 
	font-family: Arial, sans-serif; 
	font-size: 13px; 
	text-align: justify; 
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
  }
  
  .notice strong {
	font-weight: bold; 
  }
  
  .notice .close-btn-notice {
	float: right; 
	font-weight: bold; 
	color: #721c24; 
	cursor: pointer;
  }
  

