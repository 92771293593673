[data-page='landing-page'] {
	&.project-edit {
		.project-title {
			padding: 50px 0 20px;
			border-radius: 8px;
			// text-align: center;

			.content {
				width: 100%;
				border-bottom: 1px solid $blue-lighter;
				display: flex;
				justify-content: space-between;
				align-items: center;
				flex-wrap: wrap;
				padding-bottom: 20px;
				

				.title-container {
					width: 100%;
					margin-bottom: 10px;
					

					@include lg {
						width: auto;
						margin-bottom: 0;
					}

					.title {
						width: 100%;
						margin-bottom: 15px;
						color: $blue;
					}
					
					.text {
						width: 100%;
						font-size: 16px;
						line-height: 24px;
					}
				}
			}
		}

		.logo-content {
			display: inline-block;
			position: relative;

			img {
				display: inline-block;
				width: auto;
			}

			.edit-button {
				height: 40px;
				width: 40px;
				padding: 8px;
				left: 100%;
				right: auto;
				// top: 50%;
				// transform: translateY(-50%);
				// bottom: auto;
				margin-left: 10px;
				z-index: 2;

				&::before {
					content: '';
					z-index: -1;
					position: absolute;
					top: 0;
					right: 100%;
					width: 15px;
					background: rgba($white, 0.01);
				}
			}

			&:hover {
				.edit-button {
					display: block;
				}
			}
		}

		.banner {
			&.empty-banner {
				.banner-content {
					height: 100px;
					width: 100%;
					// background: $grey;
					text-align: center;
					display: flex;
					justify-content: center;
					align-items: center;

					.edit-button {
						display: inline-block;
						position: relative;
						bottom: auto;
						left: auto;
						border-radius: 10px;
						width: auto;
						height: auto;
						color: $white;
					}
				}
			}

			.banner-content {
				display: block;
				position: relative;
				margin: 0;

				&:hover {
					.edit-button {
						display: block;
					}
				}
			}
		}

		.bannner-description,
		.about-us {
			position: relative;

			&:hover {
				.edit-button {
					display: block;
				}
			}
		}

		.link-col {
			&.action {
				display: flex;
				justify-content: space-between;
				align-items: center;

				.btn {
					max-width: 100px;
					width: 49%;
					padding-left: 0;
					padding-right: 0;
					text-align: center;
					border-radius: 3px;
					
					@include md {
						max-width: none;
					}
				}
			}
		}

		.add-new-file {
			// float: right;
			height: 50px;
			margin-top: 10px;
			background: $blue;
			border: none;
			width: 100%;

			@include md {
				width: auto;
			}
		}

		.edit-button {
			position: absolute;
			bottom: 10px;
			right: 10px;
			height: 60px;
			width: 60px;
			border-radius: 50%;
			background: $blue-lighter;
			padding: 13px;
			cursor: pointer;

			&.delete-button {
				right: 80px;

				svg {
					transform: translateX(0);

					path {
						stroke-width: 2px;
					}
				}
			}

			svg {
				width: 100%;
				height: 100%;
				transform: translateX(1px);

				path {
					fill: $white;
				}
			}
		}

		.preview-page {
			.container {
				justify-content: center;

				.btn {
					display: inline-block;
				}
			}
		}

		.required-field {
			font-size: 16px;
			color: $error;
			margin-top: 0;
			margin-bottom: 10px;
			text-align: left;
		}

		.required-input {
			border: 1px solid $error;
		}

		.btn-save, .btn-delete {
			&.load {
				&::after {
					width: 18px;
					height: 18px;
				}
			}
		}
	}
}