* {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
}

button {
    background: transparent;
    border: none;
}