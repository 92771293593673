@import '../base/colors.scss';
@import '../utils/medias.scss';
    
[data-component='modal'] {
	z-index: 999;
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	overflow: auto;
	transition: opacity .1s linear, transform .1s linear;
	background: $white;
	

	&.modal-project-type {
		background: rgba($white, 0.95);
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 15px;
		height: 100%;
		width: 100%;
		border-radius: 8px;

		.content-buttons {
			.btn {
				width: 200px;
				height: 50px;

				&.active {
					background: $active;

					&:hover {
						border-color: $active;
					}
				}
			}
		}
	}

	&.modal-confidentiality {
		background: rgba($white, 0.95);
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 15px;
		height: 100%;
		width: 100%;
	}

	&.modal-cancel {
		position: fixed;
		top: 0;
		left: 0;
		background: rgba($white, 0.8);
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 15px;
		
		.content {
			.text {
				font-size: 16px;
			}
		}

		&.modal_submitted {
			.text {
				margin-bottom: 0;
			}
		}
	}

	&.modal-delete-tag {
		position: absolute;
		background: $box-shadow;
		width: 100%;
		height: 100%;

		.content {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			padding: 40px 20px;

			.text {
				font-size: 15px;

				i {
					padding: 3px 5px;
					border-radius: 5px;
					background: darken($grey, 15%);
					vertical-align: middle;
					white-space: nowrap;
				}
			}
		}
	}

	&.modal-preview {
		position: fixed;
		top: 0;
		left: 0;
		background: rgba($white, 0.8);
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 15px;

		&.modal_submitted {
			.text {
				margin-bottom: 0;
			}
		}

		align-items: center;

		@media (max-height: 900px) {
			align-items: flex-start;
		}

		.content {
			position: relative;
			max-width: 100%;
		}

		.btn-close {
			position: absolute;
			top: 10px;
			right: 10px;
			width: 30px !important;
			height: 30px;
			margin: 0 !important;
			background: transparent;

			&::before,
			&::after {
				background: $blue;
			}
		}
	}

	.content {
		max-width: 700px;
		background: $grey-light;
		border: 1px solid $grey-dark;
		text-align: center;
		padding: 40px 20px 30px;
		border-radius: 10px;

		@include sm {
			padding: 50px;
		}

		 .text {
			text-align: center;
			margin-bottom: 20px;
			font-size: 18px;
			line-height: 28px;
		}
		img {
			max-width: 308px;
			max-height: 175px;
		}

		.btn {
			margin:  10px;
			width: 100px;
			// display: inline-block;
		}
	}

	&.closed {
		opacity: 0;
		pointer-events: none;
		transform: scale(0.98);
	}

	&.opened {
		opacity: 1;
		pointer-events: all;
		transform: scale(1);
	}

	.close-modal {
		position: fixed;
		top: 20px;
		right: 50px;
		height: 50px;
		width: 50px;
		background: $blue;

		&::before,
		&::after {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			width: 45px;
			height: 2px;
			background: $white;
		}
		
		&::before {
			transform: translate(-50%, -50%) rotate(-45deg);
		}

		&::after {
			transform: translate(-50%, -50%) rotate(45deg);
		}
	}

	&.modal-project-management,
	&.modal-project-edit {
		background: rgba($black, 0.75);
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 15px;
		height: 100%;
		width: 100%;

		&.select-contacts {
			.modal-container {
				max-width: 1200px;
				border: none;

				.modal-content {
					max-height: 700px;
				}
			}
		}

		.modal-container {	
			width: 90%;
			max-width: 900px;
			//border: 2px solid $white;
			border-radius: 8px;

			.modal-header {
				height: 50px;
				width: 100%;
				background: $blue;
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 20px;
				border-top-right-radius: 8px;
				border-top-left-radius: 8px;

				.text {
					color: $white;
					font-size: 18px;
					font-weight: 700;
				}
			}

			.modal-content {
				height: 60vh;
				max-height: 500px;
				background: $white;

			}
		}

		&.h-auto {
			.modal-content {
				height: auto;
			}
		}
	}

	.modal-list-publishers {
		display: block;
		width: 100%;
		border-bottom-right-radius: 8px;
		border-bottom-left-radius: 8px;
		
		.list-publishers-header {
			width: 100%;
			background: #eff2f3;
			height: 70px;
			color: $white;
			display: flex;
			justify-content: flex-end;
			align-items: center;
			flex-wrap: wrap;
			margin-bottom: 10px;
			padding: 10px 20px;

			.toggle-type-list {
				border-radius: 5px;
				margin-left: 10px;
				background: $blue-light;
			}

			.check {
				margin-right: 100%;
				text-align-last: left;
				white-space: nowrap;

				&::before {
					background: $grey;
				}
				
				&.check-all-collaborators {
					margin-top: 5px;
					margin-left: 20px;
				}

				.text {
					color: $white;
				}
			}

			.search {
				width: 400px;
			}
		}
		
		.list-publishers-content {
			display: block;
			color: $white;
			padding: 10px 20px;
			overflow: auto;
			height: calc(100% - 80px);
			
			&.new-list {
				height: calc(100% - 130px);
			}

			.item {
				width: 100%;
				display: block;
				margin-bottom: 25px;
				text-align: left;
				color: black;

				.text {
					font-size: 16px;
					font-weight: 700;
					color: $blue;
				}

				.collaborators-list {
					padding-left: 20px;

					.check {
						margin-top: 8px;

						.text {
							color: $black;
							text-align: left;
						}
					}

					&:not(.categories-list) {
						.check {
							&:first-child {
								.text {
									font-weight: 700;
								}
							}
						}
					}

					.text {
						font-size: 16px;
						font-weight: 400;
						color: $grey-darken;
					}
				}
				.collaborators-list-cc {
					padding-left: 8px;

					.check {
						margin-top: 8px;

						.text {
							color: $black;
							text-align: left;
						}
					}

					&:not(.categories-list) {
						.check {
							&:first-child {
								.text {
									font-weight: 700;
								}
							}
						}
					}

					.text {
						font-size: 16px;
						font-weight: 400;
						color: $grey-darken;
					}
				}
			}
		}

		.save-container {
			height: 50px;
			width: 100%;
			display: flex;
			justify-content: flex-end;
			align-items: center;
			padding: 10px 30px;

			.link {
			}
		}

		.check {
			display: flex;
			padding-left: 20px;
			position: relative;
			justify-content: start;
			align-items: center;
			cursor: pointer;

			&::before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 13px;
				height: 13px;
				border-radius: 3px;
				background: $grey-dark;
				border: 1px solid transparent;
			}

			.text {
				margin-bottom: 0;
			}

			&.checked {
				&::before {
					background: $blue-light;
					border-color: $blue-light;
				}
			}

			&:hover {
				&::before {
					border-color: $blue-light;
				}
			}
		}
	}

	.modal-write-message,
	.modal-edit-description,
	.modal-edit-about,
	.modal-edit-files,
	.modal-share {
		display: block;
		width: 100%;
		padding: 10px 20px;
		background: #eff2f3 !important;
		border-bottom-right-radius: 8px;
		border-bottom-left-radius: 8px;

		form {
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: flex-end;
			align-items: center;
			flex-wrap: wrap;

			input {
				display: block;
				border-radius: 8px;
				width: 100%;
				height: 50px;
				padding: 10px;
				margin-bottom: 10px;
				border: none;
				font-size: 18px;
				color: $black;
				font-family: $primary-font;
			}
			select {
				display: block;
				border-radius: 8px;
				width: 100%;
				height: 50px;
				padding: 10px;
				margin-bottom: 10px;
				border: none;
				font-size: 18px;
				color: $black;
				font-family: $primary-font;
			}

			textarea,
			.ck-editor {
				display: block;
				width: 100%;
				height: calc(100% - 154px);
				padding: 10px;
				margin-bottom: 10px;
				border: none;
				resize: none;
				font-size: 18px;
				color: $black;
				font-family: $primary-font;
			}

			.ck-editor {
				padding: 0;

				.ck-editor__main {
					height: calc(100% - 37px);
					
					.ck-content {
						height: 100%;
						overflow: auto;
					}
				}
			}
		}

		.btn-save {
			&.load {
				color: transparent;

				&::after {
					height: 20px;
					width: 20px;
				}
			}
		}
	}

	.modal-write-message {
		form {
			textarea {
				//height: calc(50% - 60px);
				height: auto;
			}
		}

		&.with-ck-editor {
			.ck-dropdown,
			.ck-toolbar__separator {
				display: none;
			}
		}
	}

	.modal-share {
		form {
			height: calc(100% - 40px);

			textarea {
				height: calc(100% - 100px);
			}
		}

		.share-options {
			width: 100%;
			text-align: center;
			display: flex;
			justify-content: center;
			align-items: center;

			.react-sharing-button__link {
				margin: 0 5px;
				display: flex;
				justify-content: center;
				align-items: center;
				width: 120px;
				border-radius: 5px;
				height: 40px;
				text-decoration: none;

				svg {
					margin-right: 5px;

					path {
						fill: $white
					}
				}

				.react-sharing-button__text {
					color: $white;
					text-decoration: none;
				}

				&.react-sharing-button--facebook {
					background: #4267B2;
				}

				&.react-sharing-button--twitter {
					background: rgb(29, 161, 242);
				}

				&.react-sharing-button--email {
					background: #777;
				}
			}
		}
	}

	.modal-edit-about {
		max-height: 440px !important;

		textarea {
			height: calc(100% - 40px) !important;
		}
	}

	.modal-unlock-files {
		width: 100%;
		padding: 20px 20px 0;
		display: block;
		border-bottom-right-radius: 8px;
		border-bottom-left-radius: 8px;
		background: #eff2f3 !important;

		.files-header {
			height: 40px;
			padding-left: 20px;
			display: none;
	
			@include lg {
				display: block;
			}
			
			.title {
				font-weight: 700;
				color: $blue-text;
			}

			.header-action {
				text-align: center;
				padding-left: 30px;
			}
		}

		.list-files {
			display: block;
			width: 100%;
			overflow: auto;
			height: calc(100% - 50px);
			

			@include lg {
				height: calc(100% - 90px);
			}

			.link-container {
				border: 1px solid $grey-dark;
				border-radius: 5px;
				margin-bottom: 10px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 6px 10px 6px 20px;
				flex-wrap: wrap;
				background: $white;
				
				.body-action {
					text-align: center;
					padding-left: 30px;

					.btn {
						width: 48%;
					}
				}

				.col {
					&:nth-child(1) {
						order: 1;
					}

					&:nth-child(2) {
						order: 3;
						margin-top: 10px;
						
						.link-col {
							height: 60px;
							width: 100%;
							display: flex;
							justify-content: space-between;
							align-items: center;

							.text {
								max-width: 400px;
							}
						}

						@include lg {
							height: auto;
							margin-top: 0;
							order: 2;
						}
					}

					&:nth-child(3) {
						order: 2;

						@include lg {
							order: 3;
						}
					}
				}

				.link-col {
					display: flex;
					justify-content: space-between;
					align-items: center;

					&.description {
						.text {
							width: calc(100% - 80px);
						}
					}

					.btn {
						padding: 10px;
						text-align: center;
						background: $white;
						color: $black;

						&.view {
							background: $blue;
							color: $white;
							width: 80px;
							height: 40px;
							border-radius: 5px;
							display: flex;
							justify-content: center;
							align-items: center;
							font-weight: 700;
							text-transform: uppercase;
							font-size: 14px;
						}

						&.avaliable {
							background: $success;
							color: $white;
							pointer-events: none;
						}

						&.unavaliable {
							background: $red;
							color: $white;
							pointer-events: none;
						}
					}
				}
			}
		}

		.save-container {
			height: 50px;
			width: 100%;
			display: flex;
			justify-content: flex-end;
			align-items: center;
			padding: 10px 0 20px;
		}
	}	

	.modal-edit-logo,
	.modal-edit-banner {
		padding: 20px;
		text-align: right;

		@include md {
			padding: 50px;
		}

		&.required-image {
			[data-component='upload-image'] {
				.upload-image-label {
					border-color: $error;
				}
			}
		}

		.filed-infos {
			margin: 20px 0;
			text-align: left;

			.title {
				font-size: 16px;
				// color: $black;
			}
		}

		.btn-save {
			display: inline-block;
		}
	}

	.modal-edit-files {
		padding: 30px 20px;

		.btn-save {
			margin-top: 20px;
		}
	}
}