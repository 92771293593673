@import '../base/colors.scss';
@import '../utils/medias.scss';

[data-component='change-password'] {
	width: 100%;
	background: $grey-light;

	.change-password-header {
		background: $grey;
		border: 1px solid $grey-darken;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 5px 10px;

		@include md {
			padding: 5px 24px;
		}

		&.-title {
			font-size: 12px;
			font-weight: 700;
		}

		.btn {
			opacity: 1;
			transition: opacity .5s cubic-bezier(.075, .82, .165, 1);
		}
	}

	.change-password-content {
		border: 1px solid $grey-darken;
		border-top: none;
		height: 0;
		transition: height .5s cubic-bezier(.075, .82, .165, 1), padding .5s cubic-bezier(.075, .82, .165, 1);
		overflow: hidden;
		margin-top: -1px;
		display: flex;
		justify-content: flex-start;
		align-content: center;
		flex-wrap: wrap;
		padding: 0 20px;

		.row {
			margin-bottom: 15px;

			&:last-child {
				margin-bottom: 0;
			}

			&.save-button {
				.btn {
					&:first-child {
						margin-right: 10px;
					}
				}
			}
		}

		.text {
			color: $blue-dark;
			font-size: 12px;
			padding-right: 12px;

			&.text-error {
				color: $error;
				text-align: right;
			}

			&.text-success {
				color: $success;
				text-align: right;
			}
		}

		input {
			display: block;
			background: $white;
			height: 25px;
			padding: 0 10px;
			line-height: 25px;
			width: 100%;

			&::placeholder {
				line-height: 25px;
				transform: translateY(3px);
			}
		}
	}

	&.opened {
		.change-password-header {
			.btn {
				opacity: 0;
				pointer-events: none;
			}
		}
	}
}
