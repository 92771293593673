@import '../base/colors.scss';
@import '../utils/medias.scss';

[data-page='sign-up'],
[data-page='sign-in'],
[data-page='forget-password'] {
	width: 100%;
	min-height: 100vh;
	z-index: 1;
	position: relative;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	align-content: center;
	overflow: hidden;
	padding: 30px 0;
	background: $white;
	
	@include md {
		background: $blue-darken;
		padding: 0;
	}

	@include lg {
		padding: 50px 0;
		align-items: center;
	}

	&:not(.with-text) {
		.bg-logo {
			display: none;

			@include md {
				display: block;
				z-index: -1;
				position: absolute;
				right: 0;
				height: 102vh;
				width: 150vw;
				transform: translateX(40%);
			}
	
			@include lg {
				width: 100vw;
				transform: translateX(20%);
			}
	
			path {
				fill: $blue-dark;
			}
		}
	}

	.button-signin,
	.button-signup {
		background: $blue-lighter;
		margin-top: 20px;
		width: 90%;
		max-width: 600px;
		text-align: center;
		padding: 25px 0;
		font-size: 17px;
		margin: 0;
		border-radius: 8px;

		@include lg {
			margin-top: 0;
			position: absolute;
			top: 5px;
			right: 5px;
			width: 215px;
		}

		@include laptop {
			top: 30px;
			right: 25px;
		}
	}

	.container-sign-up,
	.container-sign-in {
		background: $white;
		display: flex;
		justify-content: center;
		align-items: flex-start;
		flex-wrap: wrap;
		border-radius: 8px;
		width: 90%;
		max-width: 600px;
		padding: 0 0 30px;

		@include md {
			box-shadow: 0 0 20px $box-shadow;
			padding: 60px 10%;
		}

		@include lg {
			padding: 90px 140px 60px;
		}

		.logo {
			margin-bottom: 35px;
			width: 100%;
			height: 179px;
		}

		.form {
			width: 100%;
			position: relative;
			overflow: hidden;

			@include lg {
				max-width: 345px;
			}

			.label {
				display: block;
				width: 100%;

				&:not(:first-child) {
					margin-top: -1px;
				}

				input {
					display: block;
					width: 100%;
					height: 60px;
					border: 1px solid $blue-lighter;
					color: $blue-text;
					font-size: 17px;
					text-align: center;
					padding: 10px;
					border-radius: 8px;

					&::placeholder {
						color: $blue-text;
						font-size: 17px;
					}
				}
			}

			.label-error {
				padding: 20px 10px;
				border: 1px solid $error;
				color: $error;
				margin-bottom: 20px;
				text-align: center;
				font-size: 13px;
			}

			button[type='submit'] {
				width: 100%;
				height: 60px;
				margin: 10px 0 0;
				font-size: 15px;
				position: relative;
				border-radius: 8px;

				&.load {
					font-size: 0;
					pointer-events: none;
				}
			}

			.link {
				width: 100%;
				text-align: center;
				margin-top: 20px;
				font-size: 13px;
			}
		}

		.register-complete {
			width: 100%;
			display: block;

			@include md {
				min-height: 366px;
			}

			.title {
				color: $blue-dark;
				font-size: 25px;
				line-height: 30px;
				font-weight: 700;
				text-align: center;
				margin-top: 15px;

				@include large {
					font-size: 35px;
					line-height: 40px;
				}

				&::after {
					content: "";
					display: block;
					height: 3px;
					width: 100px;
					background: $blue-light;
					margin: 25px auto;
				}
			}

			.text {
				color: $blue-darken;
				font-size: 20px;
				line-height: 24px;
				margin-bottom: 20px;
				padding-left: 0;
				position: relative;

				svg {
					position: absolute;
					top: 7px;
					left: -30px;
					width: 20px;
					height: 20px;
				}

				@include large {
					font-size: 27px;
					line-height: 34px;
				}
			}

			.link {
				color: $blue-lighter;
				font-size: 15px;
				text-decoration: underline;

				@include large {
					font-size: 22px;
				}
			}
		}
	}
	
	.container-sign-up {
		@include lg {
			padding: 0 140px 0;
		}
	}

	&.with-text {
		@include lg {
			padding: 0;
			justify-content: flex-end;
		}

		.text-holder {
			display: none;
			max-width: 530px;

			@include lg {
				display: block;
				position: absolute;
				top: 50%;
				left: 0;
				transform: translateY(-50%);
				width: 45vw;
				// min-height: 570px;
				padding: 0 2% 0 5%;
				max-width: 800px;
			}

			@include laptop {
				width: 50vw;
			}

			.title {
				color: $white;
				font-size: 3vw;
				line-height: 5vw;
				font-weight: 400;
				margin-bottom: 30px;
				display: inline-block;

				@include large {
					font-size: 2.5vw;
					line-height: 4vw;
					margin-bottom: 50px;
				}
			}

			.text {
				color: $white;
				font-size: 30px;
				font-weight: 400;
				margin-bottom: 15px;
				display: inline-block;
				line-height: 50px;

				strong {
					font-weight: 700;
				}
			}
		}

		.bg-logo {
			@include lg {
				z-index: 0;
				position: absolute;
				top: 50%;
				left: 45%;
				transform: translate(-50%, -50%);
				width: 40vw;
				opacity: 0.1;

				path {
					fill: $white;
				}
			}
			// 	position: absolute;
			// 	bottom: -25vh;
			// 	left: -10vw;
			// 	width: 50vw;
				
			// 	path {
			// 		fill: $blue-dark;
			// 	}
			// }
		}

		.container-sign-up, 
		.container-sign-in {
			@include lg {
				width: 55vw;
				min-height: 100vh;
				max-width: none;
				align-content: center;

			}

			@include laptop {
				width: 50vw;
			}
		}
	}
}

[data-page='sign-up'] {
	.container-sign-up {
		overflow: hidden;
		position: relative;
		z-index: 1;
		

		@include laptop {
			max-width: 740px;
		}
	}

	.bg-logo-right {
		@include lg {
			z-index: 0;
			position: absolute;
			top: 50%;
			left: 0;
			transform: translate(-50%, -50%);
			width: 40vw;
			opacity: 0.1;
			
			path {
				fill: $blue-darken;
			}
		}
		
		@include laptop {
			left: -10%;
		}
	}
	
	.button-signin,
	.button-signup {
		z-index: 999;
		border-radius: 8px;

		@include laptop {
			max-width: 740px;
		}
	}
}

[data-page='forget-password'] {
	.forget-password {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		opacity: 0;
		transform: translateX(110%);
		background: $white;
	}
	
	.button-signin {
		display: none;
		border-radius: 8px;

		@include lg {
			display: block;
		}
	}
}