@import '../base/colors.scss';
@import '../utils/medias.scss';
    
[data-component='main-header'] {
	position: relative;
	display: block;
	height: 100px;
	width: 100%;
	background: #1141b0;
	border-bottom-right-radius: 20px;
	border-bottom-left-radius: 20px;
	color: $white;
	position: relative;
	z-index: 9;

	@include md {
		// mobile-first
	}
	.main-logo {
		width: 40px;
		//width: 80px;
		margin-top: 9px;

		@include md {
			width: 58px;
			//width: 90px;
		}

		img {
			width: 100%;
		}
	}

	.user-menu {
		display: flex;
		justify-content: space-between;
		align-items: center;
		position: relative;
		height: 100%;
		padding-right: 30px;

		@include md {
			padding-right: 40px;
		}

		.logo-partner {
			background: $white;
			border-radius: 50%;
			display: flex;
			justify-content: center;
			align-items: center;
			overflow: hidden;
			// padding: 5px;
			border: 2px solid $blue;
			width: 45px;
			height: 45px;

			@include md {
				width: 60px;
				height: 60px;
			}

			img {
				height: 100%;
			}
		}

		.user-name-container {
			margin-left: 10px;
			text-align: left;

			@include md {
				margin-left: 15px;
			}

			.user-name {
				color: $white;
				margin-bottom: 5px;
				font-size: 12px;

				@include md {
					font-size: 15px;
				}
			}

			.company-name {
				color: $white;
				font-size: 12px;

				@include md {
					font-size: 12px;
				}
			}
		}

		&::after {
			content: '';
			width: 0; 
			height: 0; 
			border-left: 7px solid transparent;
			border-right: 7px solid transparent;
			border-top: 7px solid $white;
			position: absolute;
			top: 50%;
			right: 0;
			margin-top: -3.5px;
		}

		.user-submenu {
			width: 145px;
			position: absolute;
			top: 100%;
			right: 0; 
			padding: 11px 20px;
			background: $blue;
			border: 1px solid $grey-darken;
			border-radius: 8px;
			margin-top: 3px;
			z-index: 1;
			display: none;

			&::before {
				content: '';
				z-index: -1;
				position: absolute;
				bottom: 100%;
				left: 0;
				width: 100%;
				height: 10px;
				background: rgba($white, .001);
			}

			.link {
				width: 100%;
				color: $grey-dark;
				font-size: 13px;
				text-align: left;
				padding: 6px 0;
				display: flex;
				justify-content: start;
				align-self: auto;

				span {
					color: $white;
				}

				&:hover {
					color: $white;
				}
			}
		}

		&.opened {
			&::after {
				transform: rotate(180deg);
			}

			.user-submenu {
				display: block;
			}
		}

		&:hover {
			@include lg {
				.user-submenu {
					display: block;
				}
			}
		}
	}
	
}