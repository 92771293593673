@import '../base/colors.scss';
@import '../utils/medias.scss';

[data-component='dropdown'] {
	width: 50%;
	position: relative;
	border-radius: 3px;
	background: $blue-light;
	padding: 7px 10px;
	width: 130px;

	&::after {
		content: '';
		width: 0; 
		height: 0; 
		border-left: 6px solid transparent;
		border-right: 6px solid transparent;
		border-top: 6px solid $white;
		position: absolute;
		top: 50%;
		right: 10px;
		margin-top: -3.5px;
	}

	.dropdown-text {
		color: $white;
		font-size: 14px;
		font-weight: 600;
	}

	.dropdown-submenu {
		position: absolute;
		width: 160px;
		top: 100%;
		left: 0;
		background: $blue;
		padding: 10px;
		margin-top: 5px;
		display: none;
		z-index: 1;
		border-radius: 2px;

		&::before {
			content: '';
			z-index: -1;
			position: absolute;
			bottom: 100%;
			left: 0;
			width: 100%;
			height: 10px;
			background: rgba($white, .001);
		}

		.link {
			width: 100%;
			text-align-last: left;
			color: $grey;
			font-size: 14px;
			margin: 5px 0;
		}
	}

	&:hover {
		.dropdown-submenu {
			display: block;
		}
	}
}

[data-component='company-selection'] {
	cursor: pointer;
	background: $blue;
	color: $white;
	min-width: auto;
	width: 100%;
	text-align: center;
	position: relative;
	z-index: 100;
	white-space: nowrap;
	padding: 0 40px 0 10px;
	height: 50px;
	line-height: 50px;

	@include md {
		text-align: left;
		width: auto;
	}
	
	@include lg {
		padding: 0 40px 0 10px;
		height: 50px;
		line-height: 50px;
	}

	&::after {
		content: '';
		width: 0;
		height: 0;
		border-left: 7px solid transparent;
		border-right: 7px solid transparent;
		border-top: 7px solid $white;
		position: absolute;
		top: 50%;
		right: 15px;
		margin-top: -3.5px;
		transition: transform .5s cubic-bezier(0.785, 0.135, 0.15, 0.86);
	}

	.company-list {
		display: none;
	}

	&.active {
		&::after {
			transform: rotate(180deg);
		}

		.company-list {
			display: block;
		}
	}

	.company-list {
		position: absolute;
		top: 100%;
		margin-top: 5px;
		left: 0;
		width: 200px;
		background: $blue;
		max-height: 200px;
		overflow: auto;
		box-shadow: 0 0 5px rgba($black, 0.75);

		.company-item {
			display: block;
			width: 100%;
			background: $blue;
			border-bottom: 1px solid $blue-light;
			padding: 15px 10px;
			color: $white;
			cursor: pointer;

			&.active {
				background: $blue-light;
				pointer-events: none;
			}
		}
	}
}