@import '../base/colors.scss';
@import '../utils/medias.scss';
    
[data-component='upload-image'] {
	width: 100%;
	position: relative;

	input {
		opacity: 0;
		position: absolute;
		top: 0;
		width: 100%;
		pointer-events: none;
	}

	.upload-image-label {
		width: 100%;
		display: flex !important;
		justify-content: flex-start;
		align-content: flex-start;
		cursor: pointer;
		border: 1px dotted $grey-darken2;
		padding: 10px;
		border-radius: 5px;
	}

	.input-file-image {
		height: 60px;
		width: 60px;
		border: 1px solid $blue-dark;
		position: relative;
		display: inline-block;
		overflow: hidden;

		&::after {
			content: attr(data-size);
			position: absolute;
			top: 50%;
			left: 0;
			width: 100%;
			text-align: center;
			font-size: 11px;
			transform: translateY(-50%);
			z-index: 1;
		}

		&.active {
			&::after {
				content: none
			}
		}

		img {
			position: relative;
			width: 100%;
			height: 100%;
			z-index: 2;
			border: none;
			object-fit: contain;
		}
	}

	.input-file-content {
		display: inline-block;
		margin-left: 5px;

		.input-file-icon {
			display: block;
			margin-bottom: 10px;
		}

		.text {
			display: block;
			width: 100%;
			font-size: 14px;
			color: $blue-dark;
		}
	}
}
