%scroll-bar {
	&::-webkit-scrollbar-track{
		-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0);
		background-color: transparent;
	}

	&::-webkit-scrollbar{
		width: 4px;
		height: 4px;
		background-color: transparent;
	}
	
	&::-webkit-scrollbar-thumb{
		background-color: #00b2f3;
		border-radius: 10px;
	}
}