@import '../base/colors.scss';
@import '../utils/medias.scss';

[data-page='user'] {
	width: 100%;
	min-height: 100vh;
	background: $grey;

	[data-component='content-user'] {
		background: $white;
		padding: 30px 0;
		height: 100%;
	}

	.actions {
		&:not(:first-child) {
			margin-top: 45px;
		}

		.row {
			display: flex;
			justify-content: flex-start;
			align-items: center;

			&:first-child {
				margin-bottom: 20px;
			}
			
			.btn {
				width: 110px;
				height: 36px;
				border-radius: 5px;
				text-align: center;
				position: relative;
				font-size: 13px;

				&:first-child {
					margin-right: 10px;
				}

				&.btn-cancel {
					justify-content: center;

					.text {
						font-size: 13px;
					}
				}

				&.btn-save {
					&.load {
						color: $blue-light;

						&::after {
							height: 15px;
							width: 15px;
						}
					}
				}
			}
		}
	}

	[data-component='form'] {
		max-width: 600px;

		.client-status {
			.btn {
				&:hover {
					&:first-child {
						background: $success;
						border-color: $success;
					}

					&:last-child {
						background: $error;
						border-color: $error;
					}
				}
			}

			.row {
				&.true {
					.btn:first-child {
						background: $success;
						border-color: $success;
						color: $white;
					}
				}

				&.false {
					.btn:last-child {
						background: $error;
						border-color: $error;
						color: $white;
					}
				}
			}
		}
	}
}