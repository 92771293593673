@import '../base/colors.scss';
@import '../utils/medias.scss';

[data-page='publisher'] {
	width: 100%;
	min-height: 100vh;
	background: $grey;
	
	[data-component='content-plublisher'] {
		background: $white;
		padding: 30px 0 60px;
		height: 100%;
		min-height: calc(100vh - 149px);
		overflow: hidden;
	}

	.collaborator-form-message {
		background: $grey;

		.text-error {
			font-size: 12px;
			color: $error;
			text-align: left;
		}

		.text-success {
			font-size: 12px;
			color: $success;
			text-align: left;
		}
	}
}

[data-component='collaborators-list'] {
	display: block;
	width: 100%;
	padding: 20px 0;
	margin-top: 10px;
	position: relative;
	z-index: 1;

	.collaborators-title {
		display: block;
		font-size: 18px;
		line-height: 30px;
		font-weight: 600;
		margin-bottom: 10px;
	}

	.register-collaborator {
		margin-top: 15px;
	}

	[data-component='collaborators-table'] {
		display: block;
		width: 100%;
		border: 1px solid $grey-darken;
		border-top: none;

		@include lg {
			border-top: 1px solid $grey-darken;
		}

		.collaborators-table-header {
			justify-content: space-between;
			align-items: center;
			flex-wrap: wrap;
			height: 30px;
			padding: 0 10px;
			position: relative;
			z-index: 1;
			display: none;

			@include lg {
				display: flex;
			}

			.body-title {
				font-weight: 600;
				font-size: 14px;
			}

			.header-actions {
				text-align: center;

				.body-title {
					width: 100%;
				}
			}
		}

		.collaborators-table-body {
			flex-wrap: wrap;
			position: relative;
			z-index: 1;

			.table-item {
				width: 100%;
				display: flex;
				justify-content: space-between;
				align-items: center;
				flex-wrap: wrap;
				overflow: hidden;
				position: relative;
				border-top: 1px solid $grey-darken;
				padding: 10px 20px;

				@include lg {
					padding: 0 10px;
					height: 50px;
					justify-content: space-between;
				}

				.body-text {
					font-size: 14px;
					font-weight: 400;
					margin-bottom: 5px;

					@include lg {
						margin-bottom: 0;
						font-size: 12px;
					}

					@include laptop {
						font-size: 13px;
					}

					&.link {
						&:hover {
							text-decoration: underline;
						}
					}
				}

				.body-actions {
					justify-content: flex-end;
					transform: translateY(-2px);

					@include md {
						position: absolute;
						top: 50%;
						right: 20px;
						transform: translateY(-50%);
					}

					@include lg {
						transform: translateY(0);
						justify-content: center;
						position: relative;
						top: auto;
						right: auto;
					}

					.btn {
						padding: 5px 0;
						width: 47px;
						text-align: center;
						margin: 0 2px;
						background: $grey-dark;
						color: $white;

						&:hover {
							background: $blue-light;
							border-color: $blue-light;
						}
					}
				}

				&::before {
					content: '';
					position: absolute;
					left: 50%;
					height: 100%;
					transform: translateX(-50%);
					background: $white;
					z-index: -1;
					border: 1px solid $grey-dark;
					width: calc(100% + 28px);

					@include md {
						width: calc(100% + 98px);
					}
					@include lg {
						width: calc(100% + 148px);
					}
				}

				&:nth-child(even) {
					&::before {
						background: $grey;
					}
				}
			}
		}
	}

	.btn {
		border: none !important;
	}
}


.new-collaborator {
	position: relative;
	z-index: 1;
	padding: 20px 0;
	margin-top: 30px;

	.collaborators-title {
		font-weight: 600;
		margin-bottom: 20px;
		display: block;
	}

	&::before {
		content: '';
		background: $grey;
		z-index: -1;
		position: absolute;
		top: 0;
		left: 50%;
		height: 100%;
		width: 100vw;
		transform: translateX(-50%);
	}

	.actions {
		.btn {
			margin-right: 10px;
			border: none;

			&.btn-delete {
				background: $grey-dark;
				color: $white;

				&:hover {
					background: $grey-darken;
				}
			}
		}
	}
}

.publisher-form {
	.actions {
		margin-top: 0;
		display: flex;
		justify-content: flex-start;
		align-items: center;

		.btn {
			width: 110px;
			height: 36px;
			border-radius: 5px;
			text-align: center;
			position: relative;
			border: none;

			&:first-child {
				margin-right: 10px;
			}

			&.btn-save {
				&.load {
					color: transparent;

					&::after {
						height: 15px;
						width: 15px;
					}
				}
			}
		}
	}
}