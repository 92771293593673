@import '../base/colors.scss';
@import '../utils/medias.scss';

[data-component='search'] {
	width: 100%;
	position: relative;

	input {
		width: 100%;
		height: 32px;
		padding: 0 42px 0 10px;
		border: 1px solid $grey-dark;
		border-radius: 5px;
	}

	button  {
		position: absolute;
		border-radius: 5px;
		top: 0;
		right: 0;
		height: 100%;
		width: 32px;
		background: $grey;
		border: 1px solid $grey-dark;
		border-left: none;
		cursor: pointer;

		img {
			width: 50%;
		}
	}
}