@import './fonts.scss';
@import '../base/colors.scss';

a,
button,
span,
div {
	&.btn {
		color: $white;
		font-family: $primary-font;
		font-size: 12px;
		display: inline-block;
		text-decoration: none;
		cursor: pointer;
		padding: 8px 30px;
		background: $blue;

		&.inactive {
			cursor: default;
		}

		&.grey {
			background: $grey-light;
			border: 1px solid $grey-dark;
			border-radius: 5px;
			color: $black;

			&:not(.inactive) {
				&:hover {
					background: $grey-dark;
					color: $white;
				}
			}
		}
		
		&.dark-grey {
			background: $grey-dark;
			border: 1px solid $grey-dark;
			border-radius: 5px;
			color: $white;

			&:not(.inactive) {
				&:hover {
					background: $grey-light;
					// border-color: $grey-light;
					color: $black;
				}
			}
		}

		&.blue {
			background: $blue-light;
			border: 1px solid $grey-dark;
			border-radius: 5px;
			color: $white;

			&:not(.inactive) {
				&:hover {
					color: $white;
					background: $blue;
					border-color: $blue;
				}
			}
		}

		&.green {
			background: $active;
			border: 1px solid $active;
			border-radius: 5px;
			color: $white;

			&:not(.inactive) {
				&:hover {
					color: $white;
					background: $active2;
					border-color: $active2;
				}
			}
		}

		&.delete {
			background: $red;
			border: 1px solid $red;
			border-radius: 5px;
			color: $white;

			&:not(.inactive) {
				&:hover {
					color: $white;
					background: $delete;
					border-color: $delete;
				}
			}
		}

		&.blue-light {
			background: $blue-lighter;
			border: 1px solid $grey-dark;
			border-radius: 5px;
			color: $white;

			&:not(.inactive) {
				&:hover {
					color: $white;
					background: $blue;
					border-color: $blue;
				}
			}
		}

		&.with-icon {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 8px 20px 8px 10px;
			background: $blue-light;
			border-radius: 3px;

			&.blue-light {
				background: $blue-lighter;
				border-color: $blue-lighter;

				&:hover {
					background: $blue-light;
					border-color: $blue-light;
				}
			}

			svg {
				width: 18px;
				height: 18px;

				path {
					fill: $grey;
				}
			}

			.text {
				margin-left: 7px;
				font-size: 14px;
				color: $grey;
			}
		}

		&.btn-close {
			padding: 0;
			height: 30px;
			width: 30px;
			position: relative;

			&::before,
			&::after {
				content: '';
				position: absolute;
				top: 50%;
				left: 50%;
				height: 2px;
				width: 30px;
				background: $white;
			}
			
			&::before {
				transform: translate(-50%, -50%) rotate(45deg);
			}

			&::after {
				transform: translate(-50%, -50%) rotate(-45deg);
			}

			&:hover {
				background: transparent !important;
			}
		}

		&.load {
			pointer-events: none;
			position: relative;
			color: transparent;

			&::after {
				content: '';
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				width: 30px;
				height: 30px;
				border-top: 4px solid $white;
				border-right: 4px solid $white;
				border-bottom: 4px solid $white;
				border-left: 4px solid transparent;
				border-radius: 50%;
				animation: loading 2s infinite linear;
			}
		}

		&:not(.inactive) {
			&:hover {
				background: darken($blue, 5%);
			}
		}

		&.rounded {
			border-radius: 30px;
		}
	}

	&.link {
		color: $blue-light-text;
		cursor: pointer;
		display: inline-block;
		text-decoration: none;

		&:hover {
			color: darken($blue-light-text, 15%);
		}

		&.load {
			position: relative;
			color: transparent;
			pointer-events: none;

			&:hover {
				color: transparent;
			}

			&::after {
				content: '';
				position: absolute;
				top: 50%;
				left: 50%;
				height: 30px;
				width: 30px;
				border-top: 2px solid $blue-lighter;
				border-right: 2px solid $blue-lighter;
				border-bottom: 2px solid $blue-lighter;
				border-left: 2px solid transparent;
				animation: loading 2s infinite linear;
				border-radius: 50%;
			}
		}
	}
}

#logout-button {
	&.load {
		&::after {
			width: 10px;
			height: 10px;
		}
	}
}

@keyframes loading {
	from { transform: translate(-50%, -50%) rotate(0) }
	to { transform: translate(-50%, -50%) rotate(360deg) }
}